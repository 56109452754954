<template>
  <v-card>
    <v-tabs
      v-model="selected"
      color="success"
      active-class="white"
      background-color="#f4f3f3"
      grow
    >
      <v-tabs-slider />
      <v-tab
        v-for="scope in app_scope"
        :key="scope"
        :href="`#default-service-${scope}`"
        class="success--text"
      >
        {{ scope }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selected">
      <v-tab-item
        v-for="scope in app_scope"
        :key="scope"
        :value="`default-service-${scope}`"
      >
        <default-service :app-scope="scope" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  components: {
    DefaultService: () => import('@/components/customer/default-service/DefaultService')
  },

  data () {
    return {
      selected: null,
      app_scope: ['GLOBAL', 'Archive', 'Real-Time', 'Forecast']
    };
  }
};
</script>
